<template>
  <span
      class="nuxt-icon"
      :class="{ 'nuxt-icon--fill': !filled, 'nuxt-icon--stroke': hasStroke }"
      v-html="icon"
  />
</template>

<script setup lang="ts">
import { ref, watchEffect } from '#imports'

const props = withDefaults(defineProps<{
    name: string;
    filled?: boolean,
    stroked?: boolean
}>(), { filled: false, stroked: false })


const icon = ref<string | Record<string, any>>('')
let hasStroke = false

async function getIcon () {
    try {
        const iconsImport = import.meta.glob('assets/icons/**/**.svg', {
            as: 'raw',
            eager: false
        })
        const rawIcon = await iconsImport[`/assets/icons/${props.name}.svg`]()
        if (rawIcon.includes('stroke')) { hasStroke = true }
        icon.value = rawIcon
    } catch {
        console.error(
            `[nuxt-icons] Icon '${props.name}' doesn't exist in 'assets/icons'`
        )
    }
}

await getIcon()

watchEffect(getIcon)
</script>

<style>
.nuxt-icon svg {
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    vertical-align: middle;
}
.nuxt-icon.nuxt-icon--fill,
.nuxt-icon.nuxt-icon--fill * {
    fill: currentColor !important;
}

.nuxt-icon.nuxt-icon--stroke,
.nuxt-icon.nuxt-icon--stroke *{
    stroke: currentColor !important;
}
</style>
