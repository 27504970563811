<template>
    <IconBase
        :name="name"
        class="block"
        :style="'width: ' + width + 'px; height: ' + height + 'px'"
        :class="iconClass"
        :filled="filled"
    />
</template>

<script>
import IconBase from "~/components/ui/IconBase.vue";

export default {
    components: {IconBase},
  props: {
    name: {
      type: String,
      required: true
    },
    iconClass: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    },
    filled: {
        type: Boolean,
        default: true
    }
  },
  computed: {
    icon() {
      try {
        return this.getImageUrl(this.name, this.width, this.height);
      } catch (e) {
        console.error(`Icon not found: ${this.name}`);
        return null;
      }
    }
  },
  methods: {
    getImageUrl: (name, width, height) => {
      try {
        const url = new URL(`../../assets/icons/${name}.svg`, import.meta.url).href;
        return {
          url: url,
          viewBox: `0 0 ${width} ${height}`
        };
      } catch (e) {
        console.error(`Error loading icon: ${name}`);
        throw e;
      }
    }
  },
    setup () {
      const id = ref(Math.random() * 10000)
      return { id }
    }
}
</script>

<style>
.nuxt-icon svg {
    vertical-align: middle;
}
</style>
